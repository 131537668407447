import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { organizationSelector } from '@store/organization/selectors';
import { Disclaimer } from '@components/Disclaimer';
import { modifyProfileSettingAction } from '@store/settings';

import * as S from './LegalDisclaimerPopup.styled';

type Props = {
  className?: string;
  onClose: VoidFunction;
};

const showDisclaimerKey = 'eko__disclaimer-modal-shown';
const checkIfDisclaimerShown = () => window.sessionStorage.getItem(showDisclaimerKey) === 'true';
const setShowDisclaimerKey = () => window.sessionStorage.setItem(showDisclaimerKey, 'true');

export const LegalDisclaimerPopup: FC<Props> = ({ onClose, ...props }) => {
  const dispatch = useDispatch();
  const organization = useSelector(organizationSelector);
  const [visible, setVisible] = useState(!checkIfDisclaimerShown());
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const period = organization?.organizationInfo?.disclaimer_expiration_days_number;

  const onDontShowToggle = useCallback(() => {
    setDontShowAgain((prev) => !prev);
  }, []);

  const onConfirm = useCallback(() => {
    setVisible(false);
    onClose();
    setShowDisclaimerKey();

    if (dontShowAgain) {
      dispatch(
        modifyProfileSettingAction({
          dontShowInitialDisclaimer: true,
          hideInitialDisclaimerDate: new Date(),
        }),
      );
    }
  }, [dontShowAgain, dispatch, onClose]);

  return (
    <S.Container onClickYes={onConfirm} isVisible={visible} className={props.className}>
      <S.Content>
        <Disclaimer onLinkClick={setShowDisclaimerKey} />
        {period && period > 0 ? (
          <S.DontShowAgainCheckbox value={dontShowAgain} onChange={onDontShowToggle}>
            Don’t show{period ? ` for ${period} days` : ' this again'}
          </S.DontShowAgainCheckbox>
        ) : null}
      </S.Content>
    </S.Container>
  );
};
