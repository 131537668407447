import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { getIsAccountClosure } from '@store/profile/selectors';
import { FAQMenuItem, getMenuItems } from '~/routes';

import { MenuItemProps } from '~/entities/headerMenu';

import * as S from './HorizontalMenu.styled';

const MenuItem = ({ link }: MenuItemProps) => {
  const Icon = link.component;
  const linkMatch = useRouteMatch(link.path);

  return (
    <S.MenuItem $isActive={linkMatch?.isExact || false} link={link.path} key={link.path + link.title}>
      <S.Icon>
        <Icon />
      </S.Icon>

      {link.title && <S.Title>{link.title}</S.Title>}
    </S.MenuItem>
  );
};

const HorizontalMenu = ({ className }: { className?: string }) => {
  const closure = getIsAccountClosure();

  const links = getMenuItems(closure);

  const faqLinkConfig = useMemo(() => {
    const res = {
      path: FAQMenuItem.path,
      title: FAQMenuItem.title,
      component: FAQMenuItem.component,
    };

    res.title = '';

    return res;
  }, []);

  return (
    <>
      <S.Container className={className}>
        {links.map((link) => (
          <MenuItem key={link.path} link={link} />
        ))}
      </S.Container>
      <MenuItem link={faqLinkConfig} />
    </>
  );
};

export default HorizontalMenu;
